import React from 'react';
import {
  Navbar,
  Nav,
  NavbarBrand,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { CustomNavItem, CustomNavBrand } from './customNav';
import { CustomDropdownItem } from './customDrop';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <div>
        <Navbar color="light" light expand="md">
          <Container>
            <NavbarBrand tag={CustomNavBrand} />
            <Nav className="ml-auto" navbar>
              <CustomNavItem name="Dashboard" route="/" />
              <CustomNavItem name="Kellner" route="/user" />
              <CustomNavItem name="Raum" route="/room" />
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Produkte
                </DropdownToggle>
                <DropdownMenu right>
                  <CustomDropdownItem name="Produkte" route="/product" />
                  <CustomDropdownItem
                    name="Produktgruppen"
                    route="/productgroup"
                  />
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle nav caret>
                  Admin
                </DropdownToggle>
                <DropdownMenu right>
                  <CustomDropdownItem
                    name="Administration"
                    route="/admin/procedures"
                  />
                  <CustomDropdownItem
                    name="Passwort ändern"
                    route="/admin/password"
                  />
                  <CustomDropdownItem name="Daten" route="/admin/backup" />
                  <CustomDropdownItem
                    name="Rechnung"
                    route="/admin/usersetting"
                  />
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default Navigation;
