import React from 'react';
import PerProduct from './perProduct';
import DatePicker from 'react-datepicker';
import de from 'date-fns/locale/de';
import { Form, Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { GetDownloadProductCsv } from '../../helper/api';
import { getAuthName } from '../../auth/Auth';

const containerStyle = {
  display: 'flex',
  height: 'calc(100vh - 125px)',
};

const labelStyle = {
  marginRight: '10px',
};

const billCountStyle = {
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-around',
};

const buttonCsvStyle = {
  display: 'flex',
  flexFlow: 'column',
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(new Date().setHours(new Date().getHours() - 1)),
      endDate: new Date(),
      statistics: {},
    };

    this.setStatistics = this.setStatistics.bind(this);
  }

  addZero(i) {
    if (i < 10) {
      return `0${i}`;
    }
    return i;
  }

  toSqlDate(datestring) {
    const datetime = new Date(datestring);
    return `${datetime.getFullYear()}-${this.addZero(
      datetime.getMonth() + 1,
    )}-${this.addZero(datetime.getDate())} ${this.addZero(
      datetime.getHours(),
    )}:${this.addZero(datetime.getMinutes())}`;
  }

  handleChangeStart(e) {
    this.setState({ startDate: e });
  }

  handleChangeEnd(e) {
    this.setState({ endDate: e });
  }

  setStatistics(statistics) {
    this.setState({ statistics: statistics });
  }

  render() {
    const { startDate, endDate } = this.state;
    const { selected, countall } = this.state.statistics;

    return (
      <>
        <Form>
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label style={labelStyle}>Von</Label>
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  dateFormat="dd.MM.yy HH:mm"
                  locale={de}
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  onChange={e => this.handleChangeStart(e)}
                  maxDate={endDate}
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label style={labelStyle}>Bis</Label>
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  dateFormat="dd.MM.yy HH:mm"
                  locale={de}
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  onChange={e => this.handleChangeEnd(e)}
                  minDate={startDate}
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col md={3} style={billCountStyle}>
              <FormGroup>
                <span>
                  {selected} von {countall} Rechnungen
                </span>
              </FormGroup>
            </Col>
            <Col md={1} style={buttonCsvStyle}>
              {selected > 0 ? (
                <Button
                  href={GetDownloadProductCsv(
                    getAuthName(),
                    this.toSqlDate(startDate),
                    this.toSqlDate(endDate),
                  )}
                >
                  CSV
                </Button>
              ) : null}
            </Col>
          </Row>
        </Form>

        <div style={containerStyle}>
          <PerProduct
            setStatistics={this.setStatistics}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </>
    );
  }
}

export default Dashboard;
