import React from 'react';
import { Spinner } from 'reactstrap';

const Loading = () => {
  return (
    <div className="middle">
      <Spinner
        color="secondary"
        style={{ width: '5rem', height: '5rem' }}
        type="grow"
      />
    </div>
  );
};

export default Loading;
