import React, { Component } from 'react';
import { Table, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FaSearch, FaTrash } from 'react-icons/fa';

import { getRooms, deleteRoomWithTables } from '../../helper/api';
import Loading from '../../helper/loading';
import RoomButtons from './roomButtons';
import { getAuthName } from '../../auth/Auth';
import { toast } from 'react-toastify';

export default class room extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, rooms: [] };
  }

  withWindowConfirm = (func, text) => {
    const isYes = window.confirm(text);
    if (isYes) {
      func();
    }
  };

  fetchrooms() {
    const login = getAuthName();
    getRooms(login).then(res => {
      const rooms = res.data.json;
      this.setState({
        loading: false,
        rooms,
      });
    });
  }

  componentDidMount() {
    this.fetchrooms();
  }

  handleTrashbinClick(id) {
    this.withWindowConfirm(() => {
      this.setState({ loading: true });
      deleteRoomWithTables(id).then(() => {
        toast.success('Raum wurde erfolgreich gelöscht!');
        this.fetchrooms();
      });
    }, 'Wollen Sie wirklich diesen Raum löschen?');
  }

  render() {
    const { loading, rooms } = this.state;
    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div className="flex header-btn-grp">
              <h1>{rooms.length} Räume</h1>
              <RoomButtons />
            </div>
            <Table striped>
              <thead>
                <tr>
                  <th>Detail</th>
                  <th>Name</th>
                  <th>Löschen</th>
                </tr>
              </thead>
              <tbody className="table-withbutton">
                {rooms.map(item => (
                  <tr key={item.id}>
                    <td>
                      <NavLink to={`/room/${item.id}`}>
                        <FaSearch />
                      </NavLink>
                    </td>
                    <td>{item.name}</td>
                    <td>
                      <Button onClick={() => this.handleTrashbinClick(item.id)}>
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}
