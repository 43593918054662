import React, { Component } from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import {
  Label,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Col,
} from 'reactstrap';
import * as Yup from 'yup';
import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { getAuthName } from '../../auth/Auth';
import customInputForm from '../../helper/customInputForm';
import {
  getProductWithVariations,
  getProductGroups,
  postProductWithVariations,
} from '../../helper/api';
import Loading from '../../helper/loading';
import { toast } from 'react-toastify';

const REQUIRED = 'Dieses Feld ist ein Pflichtfeld!';

class detailProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      product: {},
      productvariationslist: [],
      productGroups: [],
    };
  }

  unformatAmount(amount) {
    return amount.toString().includes(',')
      ? parseFloat(amount.replace(',', '.')).toFixed(2)
      : parseFloat(amount).toFixed(2);
  }

  formatAmount(amount) {
    return (amount / 100).toFixed(2).replace('.', ',');
  }

  fetchData(id, login) {
    Promise.all([getProductWithVariations(id), getProductGroups(login)]).then(
      results => {
        const fixedproductvariationslist = results[0].data[0].productvariationslist.map(
          item => {
            item.amount = this.formatAmount(item.amount);
            return item;
          },
        );

        this.setState({
          loading: false,
          product: results[0].data[0],
          productvariationslist: fixedproductvariationslist,
          productGroups: results[1].data.json,
        });
      },
    );
  }

  fetchGroups(login) {
    getProductGroups(login).then(results => {
      this.setState({
        loading: false,
        productGroups: results.data.json,
      });
    });
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id === '0') {
      this.fetchGroups(getAuthName());
    } else {
      this.fetchData(id, getAuthName());
    }
  }

  onSumbmit = (values, setSubmitting) => {
    this.setState({ loading: true }, () => {
      values.amount = this.unformatAmount(values.amount);
      if (values.productvariationslist.length > 0) {
        values.productvariationslist = values.productvariationslist.map(
          item => {
            item.amount = this.unformatAmount(item.amount);
            item.login = getAuthName();
            return item;
          },
        );
      }
      values.login = getAuthName();
      postProductWithVariations(values).then(() => {
        toast.success('Produkt wurde erfolgreich gespeichert!');
        this.setState({ loading: false }, () => {
          setSubmitting(false);
          this.props.history.push(`/product`);
        });
      });
    });
  };

  render() {
    const { id } = this.props.match.params;
    const { loading, productGroups } = this.state;
    const { product, productvariationslist } = this.state;

    const initialValues = {
      id: product.id,
      productgroup_id:
        productGroups[0] == null
          ? 0
          : id === '0'
          ? productGroups[0].id
          : product.productgroup_id,
      productcolor_hex: id === '0' ? '#F5FB00' : product.productcolorhexcode,
      name: id === '0' ? '' : product.name,
      isavail: id === '0' ? 1 : product.isavail,
      amount: id === '0' ? 1 : this.formatAmount(product.amount),
      productvariationslist: productvariationslist,
      sort_id: id === '0' ? 1 : product.sort_id,
    };

    const ProductSchema = Yup.object().shape({
      name: Yup.string().required(REQUIRED),
      amount: Yup.string().when('productvariationslist', {
        is: productvariationslist => productvariationslist.length <= 0,
        then: Yup.string()
          .matches(
            /^\d+([,]\d{1,2})?$/,
            'keine gültige Nummer, nur z.B. 1 oder 2,50',
          )
          .required(REQUIRED),
      }),
      productcolor_hex: Yup.string()
        .length(7, 'kein gültiger HEX Farbcode, nur zb. #45ee44')
        .required(REQUIRED),
      productgroup_id: Yup.number()
        .positive('Keine Produktgruppe vorhanden!')
        .required(REQUIRED),
      isavail: Yup.number().required(REQUIRED),
      productvariationslist: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(REQUIRED),
          amount: Yup.string()
            .typeError('keine gültige Nummer, nur z.B. 1 oder 2,50')
            .matches(
              /^\d+([,]\d{1,2})?$/,
              'keine gültige Nummer, nur z.B. 1 oder 2,50',
            )
            .required(REQUIRED),
        }),
      ),
    });

    const ProductDetails = () => (
      <>
        <div className="flex header-btn-grp">
          {initialValues.id > 0 ? (
            <h1>Produkt bearbeiten</h1>
          ) : (
            <h1>Produkt erstellen</h1>
          )}
          <Link className="actions-btn-grp" to="/product">
            <Button outline className="actions-btn-grp">
              Abbrechen
            </Button>
          </Link>
        </div>
        <br />
        <Formik
          initialValues={initialValues}
          validationSchema={ProductSchema}
          onSubmit={(values, { setSubmitting }) =>
            this.onSumbmit(values, setSubmitting)
          }
        >
          {({ values, isSubmitting }) => (
            <Form>
              <FormGroup row>
                <Label sm={2}>Name</Label>
                <Col sm={10}>
                  <Field name="name" component={customInputForm} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Produktgruppe</Label>
                <Col sm={4}>
                  <Field
                    name="productgroup_id"
                    type="select"
                    component={customInputForm}
                  >
                    {productGroups.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                </Col>
                <Label sm={2}>Produktfarbe</Label>
                <Col sm={4}>
                  <Field
                    name="productcolor_hex"
                    type="color"
                    component={customInputForm}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>ist das Produkt verfügbar?</Label>
                <Col sm={3}>
                  <Field
                    name="isavail"
                    type="select"
                    component={customInputForm}
                  >
                    <option value={1}>Ja</option>
                    <option value={0}>Nein</option>
                  </Field>
                </Col>
                {values.productvariationslist.length > 0 ? (
                  <Label sm={6}>Kein Basisproduktpreis bei Variationen</Label>
                ) : (
                  <>
                    <Label sm={2}>Kosten</Label>
                    <Col sm={4}>
                      <InputGroup>
                        <Field name="amount" component={customInputForm} />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>€</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>{' '}
                  </>
                )}
              </FormGroup>
              <br />
              <FieldArray
                name="productvariationslist"
                render={arrayHelpers => (
                  <div>
                    {values.productvariationslist.map((friend, index) => (
                      <div key={index}>
                        <FormGroup row>
                          <Label sm={1}>Name</Label>
                          <Col sm={5}>
                            <Field
                              name={`productvariationslist.${index}.name`}
                              component={customInputForm}
                            />
                            <ErrorMessage
                              name={`productvariationslist.${index}.name`}
                            />
                          </Col>
                          <Label sm={1}>Kosten</Label>
                          <Col sm={4}>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>€</InputGroupText>
                              </InputGroupAddon>
                              <Field
                                name={`productvariationslist.${index}.amount`}
                                component={customInputForm}
                              />
                            </InputGroup>
                            <ErrorMessage
                              className="testclass"
                              name={`productvariationslist.${index}.amount`}
                            />
                          </Col>
                          <Col sm={1}>
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <FaTrash />
                            </Button>
                          </Col>
                        </FormGroup>
                      </div>
                    ))}
                    <Button
                      style={{ float: 'right' }}
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          id: 0,
                          baseproduct_id: initialValues.id,
                          name: '',
                          amount: '',
                          login: getAuthName(),
                        })
                      }
                    >
                      Neue Produktvariation
                    </Button>
                  </div>
                )}
              />
              <Button type="submit" disabled={isSubmitting}>
                {values.id > 0 ? 'Produkt speichern' : 'Produkt erstellen'}
              </Button>
            </Form>
          )}
        </Formik>
      </>
    );

    return <div>{loading ? <Loading /> : <ProductDetails />}</div>;
  }
}

export default detailProduct;
