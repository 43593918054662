import React from 'react';
import { NavLink, DropdownItem } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';

export const CustomDropdownItem = props => {
  return (
    <DropdownItem>
      <NavLink tag={RRNavLink} to={props.route} activeClassName="active">
        {props.name}
      </NavLink>
    </DropdownItem>
  );
};