import React from 'react';
import { Table, Button } from 'reactstrap';

import {
  callDelAllTablesAndRooms,
  callDelAllProductsAndVariations,
} from '../../../helper/api';

import { getAuthName } from '../../../auth/Auth';
import { toast } from 'react-toastify';

const withWindowConfirm = (func, text) => {
  const isYes = window.confirm(text);
  if (isYes) {
    func();
  }
};

const Buttons = [
  {
    id: 1,
    func: () =>
      callDelAllTablesAndRooms(getAuthName()).then(() => {
        toast.success('Räume und Tische wurden erfolgreich gelöscht!');
      }),
    desc: 'Alle Räume und Tische löschen',
  },
  {
    id: 2,
    func: () =>
      callDelAllProductsAndVariations(getAuthName()).then(() => {
        toast.success('Produkte und Variationen wurden erfolgreich gelöscht!');
      }),
    desc: 'Alle Produkte und Variationen löschen',
  },
];

function Procedures() {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>Beschreibung</th>
          <th />
        </tr>
      </thead>
      <tbody className="table-withbutton">
        {Buttons.map(item => (
          <tr key={item.id}>
            <td>{item.desc}</td>
            <td>
              <Button onClick={() => withWindowConfirm(item.func, item.desc)}>
                Ausführen
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default Procedures;
