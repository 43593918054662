import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Label, Button, FormGroup, Col } from 'reactstrap';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import customInputForm from '../../helper/customInputForm';
import {
  getProductgroupById,
  postProductgroup,
  putProductgroup,
  getPrinter,
} from '../../helper/api';
import Loading from '../../helper/loading';
import { getAuthName } from '../../auth/Auth';
import { toast } from 'react-toastify';

const REQUIRED = 'Dieses Feld ist ein Pflichtfeld!';

class detailProductgroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      productgroup: {},
      printer: [],
    };
  }

  fetchPrinter() {
    getPrinter().then(results => {
      this.setState({ printer: results.data.json, loading: false });
    });
  }

  fetchData(id) {
    Promise.all([getProductgroupById(id), getPrinter()]).then(results => {
      this.setState({
        loading: false,
        productgroup: results[0].data.json[0],
        printer: results[1].data.json,
      });
    });
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id === '0') {
      this.fetchPrinter();
    } else {
      this.fetchData(id);
    }
  }

  onSumbmit = (values, setSubmitting) => {
    const login = getAuthName();
    this.setState({ loading: true }, () => {
      const { id } = this.props.match.params;
      if (id === '0') {
        postProductgroup({
          id: values.id,
          name: values.name.trim(),
          printer_id: values.printer_id,
          login,
        }).then(() => {
          this.setState({ loading: false }, () => {
            toast.success('Prduktgruppe wurde erfolgreich gespeichert!');
            setSubmitting(false);
            this.props.history.push(`/productgroup`);
          });
        });
      } else {
        putProductgroup(values.id, {
          name: values.name.trim(),
          printer_id: values.printer_id,
        }).then(() => {
          this.setState({ loading: false }, () => {
            toast.success('Prduktgruppe wurde erfolgreich gespeichert!');
            setSubmitting(false);
            this.props.history.push(`/productgroup`);
          });
        });
      }
    });
  };

  render() {
    const { id } = this.props.match.params;
    const { loading, productgroup, printer } = this.state;

    const initialValues = {
      id: id === '0' ? '0' : productgroup.id,
      name: id === '0' ? '' : productgroup.name,
      printer_id: id === '0' ? 1 : productgroup.printer_id,
    };

    const UserSchema = Yup.object().shape({
      name: Yup.string().required(REQUIRED),
      printer_id: Yup.number().required(REQUIRED),
    });

    const ProductgroupDetails = () => (
      <>
        <div className="flex header-btn-grp">
          {initialValues.id > 0 ? (
            <h1>Produktgruppe bearbeiten</h1>
          ) : (
            <h1>Produktgruppe erstellen</h1>
          )}
          <Link className="actions-btn-grp" to="/productgroup">
            <Button outline className="actions-btn-grp">
              Abbrechen
            </Button>
          </Link>
        </div>
        <br />
        <Formik
          initialValues={initialValues}
          validationSchema={UserSchema}
          onSubmit={(values, { setSubmitting }) =>
            this.onSumbmit(values, setSubmitting)
          }
        >
          {({ values, isSubmitting }) => (
            <Form>
              <FormGroup row>
                <Label sm={2}>Produktgruppe</Label>
                <Col sm={10}>
                  <Field
                    placeholder="Produktname"
                    name="name"
                    component={customInputForm}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Drucker</Label>
                <Col sm={10}>
                  <Field
                    name="printer_id"
                    type="select"
                    component={customInputForm}
                  >
                    {printer.map(p => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </Field>
                </Col>
              </FormGroup>
              <Button type="submit" disabled={isSubmitting}>
                {values.id > 0
                  ? 'Produktgruppe speichern'
                  : 'Produktgruppe erstellen'}
              </Button>
            </Form>
          )}
        </Formik>
      </>
    );

    return <div>{loading ? <Loading /> : <ProductgroupDetails />}</div>;
  }
}

export default detailProductgroup;
