import React, { Component } from 'react';
import { Table, Button, FormGroup, Label, Col } from 'reactstrap';
import { FaTrash, FaPen } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import customInputForm from '../../helper/customInputForm';
import {
  getProductoptions,
  deletetProductoption,
  putProductoption,
  postProductoption,
} from '../../helper/api';
import Loading from '../../helper/loading';
import { getAuthName } from '../../auth/Auth';
import { toast } from 'react-toastify';

const REQUIRED = 'Dieses Feld ist ein Pflichtfeld!';

export default class room extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, groupOptions: [], optionID: '0', option: {} };

    this.formik = React.createRef();
  }

  fetchGroupOptions() {
    const login = getAuthName();
    const { id } = this.props.match.params;
    getProductoptions(login).then(res => {
      const groupOptions = res.data.json.filter(
        item => item.productgroup_id === parseFloat(id),
      );
      this.setState({
        loading: false,
        groupOptions,
      });
    });
  }

  componentDidMount() {
    this.fetchGroupOptions();
  }

  selectOption(option) {
    this.setState({ option, optionID: option.id }, () => {
      this.formik.current.resetForm();
    });
  }

  handleTrashbinClick(id) {
    deletetProductoption(id).then(() => {
      toast.success('Gruppenoption wurde erfolgreich gelöscht!');
      this.fetchGroupOptions();
    });
  }

  onSumbmit = (values, setSubmitting, resetForm) => {
    const login = getAuthName();
    this.setState({ loading: true }, () => {
      const { optionID, groupOptions } = this.state;
      if (optionID === '0') {
        postProductoption({
          name: values.name.trim(),
          productgroup_id: values.productgroup_id,
          sort: 1,
          login,
        }).then(res => {
          const container = groupOptions;
          container.push(res.data.json[0]);
          this.setState(
            {
              loading: false,
              optionID: '0',
              option: {},
              groupOptions: container,
            },
            () => {
              setSubmitting(false);
              resetForm();
            },
          );
        });
      } else {
        putProductoption(values.id, {
          name: values.name.trim(),
        }).then(res => {
          const container = groupOptions;
          container.splice(
            container.findIndex(item => item.id === res.data.json[0]),
            1,
          );
          container.push(res.data.json[0]);
          this.setState(
            {
              loading: false,
              optionID: '0',
              option: {},
              groupOptions: container,
            },
            () => {
              setSubmitting(false);
              resetForm();
            },
          );
        });
      }
    });
  };

  render() {
    const { id } = this.props.match.params;
    const { loading, groupOptions, option, optionID } = this.state;

    const initialValues = {
      id: optionID === '0' ? '0' : option.id,
      name: optionID === '0' ? '' : option.name,
      productgroup_id: id,
    };

    const UserSchema = Yup.object().shape({
      name: Yup.string().required(REQUIRED),
    });

    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div className="flex header-btn-grp">
              <h1>{groupOptions.length} Produktoptionen</h1>
              <Link to={`/productgroupoptions/sort/${id}`}>
                <Button className="actions-btn-grp">Sortierung ändern</Button>
              </Link>
            </div>
            <Table striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Bearbeiten</th>
                  <th>Löschen</th>
                </tr>
              </thead>
              <tbody className="table-withbutton">
                {groupOptions.map(item => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>
                      <Button onClick={() => this.selectOption(item)}>
                        <FaPen />
                      </Button>
                    </td>
                    <td>
                      <Button onClick={() => this.handleTrashbinClick(item.id)}>
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Formik
              ref={this.formik}
              initialValues={initialValues}
              validationSchema={UserSchema}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                this.onSumbmit(values, setSubmitting, resetForm)
              }
            >
              {({ values, isSubmitting }) => (
                <Form>
                  <FormGroup row>
                    <Label sm={2}>Option</Label>
                    <Col sm={10}>
                      <Field
                        placeholder="mit Ketchup.."
                        name="name"
                        component={customInputForm}
                      />
                    </Col>
                  </FormGroup>
                  <Button type="submit" disabled={isSubmitting}>
                    {values.id > 0 ? 'Option speichern' : 'Option erstellen'}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    );
  }
}
