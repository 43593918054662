import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';

import { Link } from 'react-router-dom';

function productActionButtonGroup() {
  return (
    <ButtonGroup className="actions-btn-grp">
      <Link to="/product/0">
        <Button>Neues Produkt</Button>
      </Link>
      <Link to="/product/sort">
        <Button>Sortierung ändern</Button>
      </Link>
    </ButtonGroup>
  );
}

export default productActionButtonGroup;
