import React from 'react';
import { ButtonGroup } from 'reactstrap';
import NewRoomModal from './newRoomModal';

function roomButtons() {
  return (
    <ButtonGroup className="actions-btn-grp">
      <NewRoomModal buttonLabel="Neuen Raum erstellen" />
    </ButtonGroup>
  );
}

export default roomButtons;
