import React, { Component } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Loading from '../../helper/loading';
import {
  getChartPerProductByDate,
  getCountChartByDate,
} from '../../helper/api';
import { toast } from 'react-toastify';
import { getAuthName } from '../../auth/Auth';

class PerProduct extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: true, data: [] };
  }

  addZero(i) {
    if (i < 10) {
      return `0${i}`;
    }
    return i;
  }

  toSqlDate(datestring) {
    const datetime = new Date(datestring);
    return `${datetime.getFullYear()}-${this.addZero(
      datetime.getMonth() + 1,
    )}-${this.addZero(datetime.getDate())} ${this.addZero(
      datetime.getHours(),
    )}:${this.addZero(datetime.getMinutes())}`;
  }

  shouldComponentUpdate(nextProps) {
    const { startDate, endDate } = this.props;

    if (startDate !== nextProps.startDate || endDate !== nextProps.endDate) {
      this.fetchData(nextProps.startDate, nextProps.endDate);
    }
    return true;
  }

  fetchData(startDate, endDate) {
    const login = getAuthName();
    const { setStatistics } = this.props;
    const sqlStartDate = this.toSqlDate(startDate);
    const sqlEndDate = this.toSqlDate(endDate);

    this.setState({ loading: true }, () => {
      Promise.all([
        getChartPerProductByDate(login, sqlStartDate, sqlEndDate),
        getCountChartByDate(login, sqlStartDate, sqlEndDate),
      ]).then(res => {
        setStatistics(res[1].data[0]);
        this.setState({ loading: false, data: res[0].data }, () => {
          toast.info('Daten aktualisiert.');
        });
      });
    });
  }

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.fetchData(startDate, endDate);
  }

  render() {
    const { data, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (data.length < 1) {
      toast.warn('Keine Daten, bitte Suche ändern.');
      return <p>Keine Daten</p>;
    }

    return (
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="productname" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" unit=" €" />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="#82ca9d"
            unit=" Stück"
          />
          <Tooltip />
          <Bar yAxisId="left" dataKey="amount" unit=" €" fill="#8884d8" />
          <Bar yAxisId="right" dataKey="count" unit=" Stück" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default PerProduct;
