import React, { Component } from 'react';
import {
  Table,
  Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FaTrash, FaSearch } from 'react-icons/fa';

import { getAuthName } from '../../auth/Auth';
import {
  getProductsWithVariations,
  deleteProductWithVariations,
} from '../../helper/api';
import Loading from '../../helper/loading';
import ProductActionButtonGroup from './productActionButtonGroup';
import { toast } from 'react-toastify';

export default class room extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      products: [],
      filteredProducts: [],
      groups: [],
      dropdownOpen: false,
    };
  }

  withWindowConfirm = (func, text) => {
    const isYes = window.confirm(text);
    if (isYes) {
      func();
    }
  };

  fetchproducts() {
    getProductsWithVariations(getAuthName()).then(res => {
      const products = res.data;
      const filteredProducts = products.sort(
        (a, b) => a.productgroup_id - b.productgroup_id,
      );
      const groups = res.data
        .map(item => item.productgroupname)
        .filter((value, index, self) => self.indexOf(value) === index);
      this.setState({ loading: false, filteredProducts, products, groups });
    });
  }

  componentDidMount() {
    this.fetchproducts();
  }

  handleSetFilterClick(group) {
    const { products } = this.state;
    group === 'Alle'
      ? this.setState({
          filteredProducts: products.sort(
            (a, b) => a.productgroup_id - b.productgroup_id,
          ),
        })
      : this.setState({
          filteredProducts: products
            .filter(item => item.productgroupname === group)
            .sort((a, b) => a.productgroup_id - b.productgroup_id),
        });
  }

  formatAmount(amount) {
    return (amount / 100).toFixed(2).replace('.', ',');
  }

  handleTrashbinClick(product) {
    this.withWindowConfirm(
      () =>
        this.setState({ loading: true }, () => {
          deleteProductWithVariations(product.id).then(() => {
            toast.success('Produkt wurde erfolgreich gelöscht!');
            this.fetchproducts();
          });
        }),
      `Wollen Sie wirklich ${product.name} löschen?`,
    );
  }

  render() {
    const { loading, dropdownOpen, filteredProducts, groups } = this.state;

    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex header-btn-grp">
              <h1>{filteredProducts.length} Produkte</h1>
              <ProductActionButtonGroup />
            </div>
            <Table striped>
              <thead>
                <tr>
                  <th>Detail</th>
                  <th>Name</th>
                  <th>
                    <ButtonDropdown
                      className="table-btn-filter"
                      isOpen={dropdownOpen}
                      toggle={() => {
                        this.setState({ dropdownOpen: !dropdownOpen });
                      }}
                    >
                      <DropdownToggle caret color="link">
                        Gruppe
                      </DropdownToggle>
                      <DropdownMenu>
                        {groups.map(item => (
                          <DropdownItem
                            onClick={() => this.handleSetFilterClick(item)}
                            key={item}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                        {groups.length > 0 ? (
                          <>
                            <DropdownItem divider />
                            <DropdownItem
                              onClick={() => this.handleSetFilterClick('Alle')}
                              key="Alle"
                            >
                              Alle
                            </DropdownItem>
                          </>
                        ) : null}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </th>
                  <th>Produktvariationen</th>
                  <th>Preis</th>
                  <th>Farbe</th>
                  <th>Löschen</th>
                </tr>
              </thead>
              <tbody className="table-withbutton">
                {filteredProducts.map(item => (
                  <tr key={item.id}>
                    <td>
                      <NavLink to={`/product/${item.id}`}>
                        <FaSearch />
                      </NavLink>
                    </td>
                    {item.isavail === 1 ? (
                      <td>{item.name}</td>
                    ) : (
                      <td>
                        <del>{item.name}</del>
                      </td>
                    )}
                    <td>{item.productgroupname}</td>
                    <td>
                      {item.productvariationslist.length > 0
                        ? item.productvariationslist.length
                        : '-'}
                    </td>
                    <td>
                      {item.productvariationslist.length > 0
                        ? '-'
                        : this.formatAmount(item.amount) + ' €'}
                    </td>
                    <td>
                      <div style={{ width: 40, height: 40, backgroundColor: item.productcolorhexcode}}>
                      </div>
                    </td>
                    <td>
                      <Button onClick={() => this.handleTrashbinClick(item)}>
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </>
    );
  }
}
