import React, { Component } from 'react';
import { Button, Table, ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { getAuthName } from '../../auth/Auth';
import NewTableModal from './newTableModal';
import { deleteTable, getRoomWithTables } from '../../helper/api';
import Loading from '../../helper/loading';

export class detailRoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tables: [],
    };
  }

  fetchRoomWithTables(id) {
    const login = getAuthName();
    this.setState({ loading: true });
    getRoomWithTables(id, login).then(res => {
      this.setState({
        loading: false,
        tables: res.data.sort((a, b) => a.name - b.name),
      });
    });
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.fetchRoomWithTables(id);
  }

  handleTrashbinClick(id) {
    deleteTable(id).then(() => {
      toast.success('Tisch wurde erfolgreich gelöscht!');
      const { id } = this.props.match.params;
      this.fetchRoomWithTables(id);
    });
  }

  render() {
    const { id } = this.props.match.params;
    const { loading, tables } = this.state;
    const RoomDetails = () => (
      <div>
        <div className="flex header-btn-grp">
          <h1>{tables.length} Tische</h1>
          <ButtonGroup className="actions-btn-grp">
            <NewTableModal buttonLabel="Tische hinzufügen" roomId={id} />
            <Link className="actions-btn-grp" to="/room">
              <Button outline className="actions-btn-grp">
                Abbrechen
              </Button>
            </Link>
          </ButtonGroup>
        </div>
        <Table striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Löschen</th>
            </tr>
          </thead>
          <tbody>
            {tables.map(item => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>
                  <Button onClick={() => this.handleTrashbinClick(item.id)}>
                    <FaTrash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );

    return <div>{loading ? <Loading /> : <RoomDetails />}</div>;
  }
}

export default detailRoom;
