import { PostLoginCredentials } from '../helper/api';
import { toast } from 'react-toastify';

const KEY = 'AUTH';

export function isAuthenticated() {
  const user = JSON.parse(sessionStorage.getItem(KEY));
  return user === null ? false : true;
}

export async function signIn(login, password) {
  const res = await PostLoginCredentials({ login, password });
  const { isAuth, isAdmin, loginError, token } = res.data;

  if (loginError === '') {
    const data = JSON.stringify({ login, isAdmin, token });
    sessionStorage.setItem(KEY, data);
    toast.success(`Erfolgreich als ${login} angemeldet!`);

    return { isAuth, loginError };
  } else {
    return { isAuth, loginError };
  }
}

export function isAdmin() {
  const user = JSON.parse(sessionStorage.getItem(KEY));

  if (user && user.isAdmin) {
    return user.isAdmin;
  }
  return false;
}

export function getAuthName() {
  const user = JSON.parse(sessionStorage.getItem(KEY));
  if (user && user.login) {
    return user.login;
  }
  return '';
}

export function signOut() {
  toast.success('Erfolgreich abgemeldet!');
  sessionStorage.clear();
}
