import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Col, Row, Form, FormGroup, FormText, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';

import { postNewRoom } from '../../helper/api';
import { getAuthName } from '../../auth/Auth';

class newRoomModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      name: '',
      fromNumber: 1,
      toNumber: 10,
      prefix: '',
      postfix: '',
      login: getAuthName(),
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    postNewRoom(this.state).then(() => {
      toast.success('Raum wurde erfolgreich erstellt!');
      this.setState({ modal: !this.state.modal });
    });
  }

  render() {
    const { name, fromNumber, toNumber, prefix, postfix } = this.state;
    return (
      <div>
        <Button color="secondary" onClick={this.toggle}>
          {this.props.buttonLabel}
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Neuer Raum</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Schank, Zelt, ..."
                  required
                  value={name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="fromNumber">Tisch Nummer von</Label>
                    <Input
                      type="number"
                      name="fromNumber"
                      id="fromNumber"
                      placeholder="1"
                      required
                      value={fromNumber}
                      onChange={e =>
                        this.setState({ fromNumber: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="toNumber">Tisch Nummer bis</Label>
                    <Input
                      type="number"
                      name="toNumber"
                      id="toNumber"
                      placeholder="99"
                      required
                      value={toNumber}
                      onChange={e =>
                        this.setState({ toNumber: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="prefix">Prefix</Label>
                    <Input
                      type="text"
                      name="prefix"
                      id="prefix"
                      value={prefix}
                      onChange={e => this.setState({ prefix: e.target.value })}
                    />
                    <FormText>Prefix Z: Z1, Z2, Z3, ...</FormText>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="postfix">Postfix</Label>
                    <Input
                      type="text"
                      name="postfix"
                      id="postfix"
                      value={postfix}
                      onChange={e => this.setState({ postfix: e.target.value })}
                    />
                    <FormText>Suffix B: 1B, 2B, 3B, ...</FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Button type="submit" color="primary">
                Erstellen
              </Button>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default newRoomModal;
