import React from 'react';
import { NavLink, NavItem } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';

import { getAuthName, signOut } from '../auth/Auth';

const logOutSignStyle = {
  marginLeft: '0.5em',
};

export const CustomNavItem = props => {
  return (
    <NavItem>
      <NavLink tag={RRNavLink} to={props.route} activeClassName="active">
        {props.name}
      </NavLink>
    </NavItem>
  );
};

function withWindowConfirm(func, text) {
  const isYes = window.confirm(text);
  if (isYes) {
    func();
  }
}

function LogOutRedirect() {
  withWindowConfirm(signOut, 'Wollen Sie sich wirklich abmelden?');
}

const LogoName = () => {
  const AuthName = getAuthName();
  return (
    <>
      {AuthName === '' ? 'Bonos - Browser NEU starten!' : `Bonos - ${AuthName}`}
      {AuthName === '' ? null : (
        <FaSignOutAlt
          style={logOutSignStyle}
          onClick={() => LogOutRedirect()}
        />
      )}
    </>
  );
};

export function CustomNavBrand() {
  return (
    <NavLink className="navbar-brand" tag={RRNavLink} to="/">
      <LogoName />
    </NavLink>
  );
}
