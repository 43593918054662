import React from 'react';
import { Redirect } from 'react-router-dom';

import { signIn } from '../auth/Auth';

const formStyle = {
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  padding: '3em 3em',
  boxShadow: '#ab5200 10px 10px 10px 3px',
  borderRadius: '8px',
};

const bonosStyle = {
  color: 'white',
  marginBottom: '32px',
};

const inputStyle = {
  border: '0',
  padding: '10px 18px',
  borderBottom: '1px solid #eee',
};

const inputContainerStyle = {
  display: 'flex',
  flexFlow: 'column',
  borderRadius: '10px',
  overflow: 'hidden',
};

const submitButtonStyle = {
  paddingBottom: '10px',
  paddingTop: '10px',
  cursor: 'pointer',
  background: 'none',
  border: '1px solid rgba(255, 255, 255, 0.65)',
  borderRadius: '25px',
  color: 'white',
  width: '100%',
  marginTop: '2.5em',
};

const errorStyle = {
  marginTop: '1em',
  color: '#bb0000',
};

export class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginError: '',
      isAuth: false,
    };
  }

  onSubmitLogin = event => {
    event.preventDefault();
    signIn(event.target.login.value, event.target.password.value).then(res => {
      this.setState({ loginError: res.loginError, isAuth: res.isAuth });
    });
  };

  render() {
    const { loginError, isAuth } = this.state;

    if (isAuth) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <form style={formStyle} onSubmit={event => this.onSubmitLogin(event)}>
          <h1 style={bonosStyle}>Bonos</h1>
          <div style={inputContainerStyle}>
            <input
              className="logininput"
              style={inputStyle}
              type="text"
              placeholder="Login"
              autoComplete="on"
              name="login"
            />
            <input
              className="logininput"
              style={inputStyle}
              type="password"
              placeholder="Passwort"
              autoComplete="on"
              name="password"
            />
          </div>
          {loginError === '' ? null : <h5 style={errorStyle}>{loginError}</h5>}
          <button
            className="loginbutton"
            style={submitButtonStyle}
            type="submit"
          >
            Login
          </button>
        </form>
      </div>
    );
  }
}

export default LoginForm;
