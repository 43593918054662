import React, { Component } from 'react';
import { Table, Button } from 'reactstrap';
import { FaTrash, FaSearch, FaPen } from 'react-icons/fa';
import { NavLink, Link } from 'react-router-dom';

import {
  getProductGroups,
  deleteProductGroup,
  getPrinter,
} from '../../helper/api';
import Loading from '../../helper/loading';
import { getAuthName } from '../../auth/Auth';
import { toast } from 'react-toastify';

export class Productgroup extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, productgroup: [], printer: [] };
  }

  withWindowConfirm = (func, text) => {
    const isYes = window.confirm(text);
    if (isYes) {
      func();
    }
  };

  fetchPrinter() {
    getPrinter().then(results => {
      this.setState({ printer: results.data.json });
    });
  }

  fetchProductgroup() {
    const login = getAuthName();
    getProductGroups(login).then(results => {
      this.setState({ productgroup: results.data.json });
    });
  }

  fetchData() {
    Promise.all([this.fetchPrinter(), this.fetchProductgroup()]).then(() => {
      this.setState({ loading: false });
    });
  }

  handleTrashbinClick(id) {
    this.withWindowConfirm(() => {
      this.setState({ loading: true }, () => {
        deleteProductGroup(id)
          .then(() => {
            toast.success('Produktgruppe wurde erfolgreich gelöscht!');
            this.fetchData();
          })
          .catch(() => {
            toast.error(
              'Fehler! Achtung, funktioniert nur, wenn kein Produkt mehr in der Gruppe ist!',
            );
          });
      });
    }, 'Wollen Sie diese Produktgruppe wirklich löschen?');
  }

  getPrinterName(printer, printerid) {
    return (
      (printerid &&
        printer &&
        printer[printer.findIndex(p => p.id === printerid)] &&
        printer[printer.findIndex(p => p.id === printerid)].name) ||
      '-'
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { loading, productgroup, printer } = this.state;
    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div className="flex header-btn-grp">
              <h1>{productgroup.length} Produktgruppen</h1>
              <Link to="/productgroup/0">
                <Button className="actions-btn-grp">Neue Produktgruppe</Button>
              </Link>
            </div>
            <Table className="table-withbutton" striped>
              <thead>
                <tr>
                  <th>Detail</th>
                  <th>Name</th>
                  <th>Drucker</th>
                  <th>Produktoptionen</th>
                  <th>Löschen</th>
                </tr>
              </thead>
              <tbody>
                {productgroup.map(pg => (
                  <tr key={pg.id}>
                    <td>
                      <NavLink to={`/productgroup/${pg.id}`}>
                        <FaSearch />
                      </NavLink>
                    </td>
                    <td>{pg.name}</td>
                    <td>{this.getPrinterName(printer, pg.printer_id)}</td>
                    <td>
                      <NavLink to={`/productgroupoptions/${pg.id}`}>
                        <FaPen />
                      </NavLink>
                    </td>
                    <td>
                      <Button onClick={() => this.handleTrashbinClick(pg.id)}>
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </>
    );
  }
}

export default Productgroup;
