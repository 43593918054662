import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Label, Button, FormGroup, Col } from 'reactstrap';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import customInputForm from '../../helper/customInputForm';
import { getUserById, postUser, putUser } from '../../helper/api';
import Loading from '../../helper/loading';
import { getAuthName } from '../../auth/Auth';

const REQUIRED = 'Dieses Feld ist ein Pflichtfeld!';

class detailUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: {},
    };
  }

  fetchUser(id) {
    this.setState({ loading: true }, () => {
      getUserById(id).then(results => {
        this.setState({
          loading: false,
          user: results.data.json[0],
        });
      });
    });
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id !== '0') {
      this.fetchUser(id);
    }
  }

  onSumbmit = (values, setSubmitting) => {
    const login = getAuthName();
    this.setState({ loading: true }, () => {
      const { id } = this.props.match.params;
      if (id === '0') {
        postUser({
          id: values.id,
          username: values.username.trim(),
          password: values.password.trim(),
          login,
        }).then(() => {
          this.setState({ loading: false }, () => {
            toast.success('Kellner wurde erfolgreich gespeichert!');
            setSubmitting(false);
            this.props.history.push(`/user`);
          });
        });
      } else {
        putUser(values.id, {
          username: values.username.trim(),
          password: values.password.trim(),
        }).then(() => {
          this.setState({ loading: false }, () => {
            toast.success('Kellner wurde erfolgreich gespeichert!');
            setSubmitting(false);
            this.props.history.push(`/user`);
          });
        });
      }
    });
  };

  render() {
    const { id } = this.props.match.params;
    const { loading, user } = this.state;

    const initialValues = {
      id: id === '0' ? '0' : user.id,
      username: id === '0' ? '' : user.username,
      password: id === '0' ? '' : user.password,
    };

    const UserSchema = Yup.object().shape({
      username: Yup.string().required(REQUIRED),
      password: Yup.string().required(REQUIRED),
    });

    const UserDetails = () => (
      <>
        <div className="flex header-btn-grp">
          {initialValues.id > 0 ? (
            <h1>Kellner bearbeiten</h1>
          ) : (
            <h1>Kellner erstellen</h1>
          )}
          <Link className="actions-btn-grp" to="/user">
            <Button outline className="actions-btn-grp">
              Abbrechen
            </Button>
          </Link>
        </div>
        <br />
        <Formik
          initialValues={initialValues}
          validationSchema={UserSchema}
          onSubmit={(values, { setSubmitting }) =>
            this.onSumbmit(values, setSubmitting)
          }
        >
          {({ values, isSubmitting }) => (
            <Form>
              <FormGroup row>
                <Label sm={2}>Kellnername</Label>
                <Col sm={10}>
                  <Field
                    placeholder="Kellnername"
                    name="username"
                    component={customInputForm}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Passwort</Label>
                <Col sm={10}>
                  <Field
                    placeholder="Passwort"
                    name="password"
                    component={customInputForm}
                    type="password"
                  />
                </Col>
              </FormGroup>
              <Button type="submit" disabled={isSubmitting}>
                {values.id > 0 ? 'Kellner speichern' : 'Kellner erstellen'}
              </Button>
            </Form>
          )}
        </Formik>
      </>
    );

    return <div>{loading ? <Loading /> : <UserDetails />}</div>;
  }
}

export default detailUser;
