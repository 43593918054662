import React, { Component } from 'react';

import LoginForm from './loginForm';

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  // background

  // eslint-disable-next-line
  background: '#FAD961' /* fallback for old browsers */,
  // eslint-disable-next-line
  background:
    '-webkit-linear-gradient(90deg, #FAD961 0%, #F76B1C 100%)' /* Chrome 10-25, Safari 5.1-6 */,
  // eslint-disable-next-line
  background:
    'linear-gradient(90deg, #FAD961 0%, #F76B1C 100%)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
};

export class LoginPage extends Component {
  render() {
    return (
      <div style={containerStyle}>
        <LoginForm />
      </div>
    );
  }
}

export default LoginPage;
