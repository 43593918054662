import React from 'react';
import { Button, Col } from 'reactstrap';

import { GetDownloadDataString } from '../../../helper/api';
import UploadDropZone from './uploadDropZone';
import { getAuthName } from '../../../auth/Auth';

const colStyle = { marginTop: '3.5rem' };

function Backup() {
  return (
    <div>
      <Col style={colStyle}>
        <h2>Daten Download</h2>
        <p>
          Produkte + Variationen, Produktgruppen, Gruppenoptionen, Räume +
          Tische, Kellner, Rechnungsoptionen für lokal Downloaden
        </p>
        <Button href={GetDownloadDataString(getAuthName())}>Download</Button>
      </Col>
      <Col style={colStyle}>
        <h2>Rechnungs Upload</h2>
        <UploadDropZone />
      </Col>
    </div>
  );
}

export default Backup;
