import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Label, Button, FormGroup, Col } from 'reactstrap';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import customInputForm from '../../../helper/customInputForm';
import { getUsersettingByLogin, putUsersetting } from '../../../helper/api';
import Loading from '../../../helper/loading';
import { getAuthName } from '../../../auth/Auth';

const REQUIRED = 'Dieses Feld ist ein Pflichtfeld!';

class Usersetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      usersetting: {},
    };
  }

  componentDidMount() {
    const login = getAuthName();
    this.setState({ loading: true }, () => {
      getUsersettingByLogin(login).then(results => {
        this.setState({
          loading: false,
          usersetting: results.data.json[0],
        });
      });
    });
  }

  onSumbmit = (values, setSubmitting) => {
    this.setState({ loading: true }, () => {
      putUsersetting(values.id, {
        beforebill: values.beforebill.trim(),
        afterbill: values.afterbill.trim(),
      }).then(() => {
        this.setState({ loading: false }, () => {
          toast.success('Optionen wurden erfolgreich gespeichert!');
          setSubmitting(false);
          this.props.history.push(`/`);
        });
      });
    });
  };

  render() {
    const { loading, usersetting } = this.state;

    const initialValues = {
      id: usersetting.id,
      beforebill: usersetting.beforebill,
      afterbill: usersetting.afterbill,
    };

    const UsersettingSchema = Yup.object().shape({
      beforebill: Yup.string().required(REQUIRED),
      afterbill: Yup.string().required(REQUIRED),
    });

    const UsersettingForm = () => (
      <>
        <div className="flex header-btn-grp">
          <h1>Rechnungsoptionen</h1>
          <Link className="actions-btn-grp" to="/">
            <Button outline className="actions-btn-grp">
              Abbrechen
            </Button>
          </Link>
        </div>
        <br />
        <Formik
          initialValues={initialValues}
          validationSchema={UsersettingSchema}
          onSubmit={(values, { setSubmitting }) =>
            this.onSumbmit(values, setSubmitting)
          }
        >
          {({ isSubmitting }) => (
            <Form>
              <FormGroup row>
                <Label sm={2}>Überschrift</Label>
                <Col sm={10}>
                  <Field
                    placeholder="Organisation xyz"
                    name="beforebill"
                    component={customInputForm}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Fußzeile</Label>
                <Col sm={10}>
                  <Field
                    placeholder="Vielen Dank!"
                    name="afterbill"
                    component={customInputForm}
                  />
                </Col>
              </FormGroup>
              <Button type="submit" disabled={isSubmitting}>
                Speichern
              </Button>
            </Form>
          )}
        </Formik>
      </>
    );

    return <div>{loading ? <Loading /> : <UsersettingForm />}</div>;
  }
}

export default Usersetting;
