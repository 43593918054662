import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, FormGroup, Label, Col } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import customInputForm from '../../../helper/customInputForm';
import { getAuthName } from '../../../auth/Auth';
import { PostNewPassword } from '../../../helper/api';
import { toast } from 'react-toastify';

export class Password extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onSumbmit = (values, setSubmitting) => {
    const login = getAuthName();
    this.setState({ loading: true }, () => {
      PostNewPassword(values.password, login).then(() => {
        toast.success('Passwort wurde erfolgreich gespeichert!');
        this.setState({ loading: false }, () => {
          setSubmitting(false);
          this.props.history.push(`/user`);
        });
      });
    });
  };

  render() {
    const initialValues = {
      password: '',
      passwordConfirm: '',
    };

    const UserSchema = Yup.object({
      password: Yup.string().required('Dies ist ein Pflichtfeld!'),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwörter müssen übereinstimmen!')
        .required('Dies ist ein Pflichtfeld!'),
    });

    return (
      <>
        <div className="flex header-btn-grp">
          <h1>Passwort ändern</h1>
          <Link className="actions-btn-grp" to="/user">
            <Button outline className="actions-btn-grp">
              Abbrechen
            </Button>
          </Link>
        </div>
        <br />
        <Formik
          initialValues={initialValues}
          validationSchema={UserSchema}
          onSubmit={(values, { setSubmitting }) =>
            this.onSumbmit(values, setSubmitting)
          }
        >
          {({ isSubmitting }) => (
            <Form>
              <FormGroup row>
                <Label sm={4}>Passwort</Label>
                <Col sm={8}>
                  <Field
                    placeholder="Passwort"
                    name="password"
                    component={customInputForm}
                    type="password"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4}>Passwort wiederholen</Label>
                <Col sm={8}>
                  <Field
                    placeholder="Passwort wiederholen"
                    name="passwordConfirm"
                    component={customInputForm}
                    type="password"
                  />
                </Col>
              </FormGroup>
              <Button type="submit" disabled={isSubmitting}>
                Passwort speichern
              </Button>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default Password;
