import React, { Component } from 'react';
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonGroup,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { getProductGroups, getProducts, putProduct } from '../../helper/api';
import Loading from '../../helper/loading';
import { getAuthName } from '../../auth/Auth';
import { toast } from 'react-toastify';

const SortableItem = sortableElement(({ value }) => (
  <div
    style={{ backgroundColor: value.productcolor_hex }}
    className="sortable-item"
  >
    <span>{value.name}</span>
  </div>
));

const SortableContainer = sortableContainer(({ items }) => {
  return (
    <div className="sortable-container">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  );
});

export class reorderProducts extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      products: [],
      sortedProducts: [],
      filter: '',
      groups: [],
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    const login = getAuthName();
    Promise.all([getProductGroups(login), getProducts(login)]).then(results => {
      this.setState({
        groups: results[0].data.json,
        filter: results[0].data.json[0].name,
        sortedProducts: results[1].data.json
          .filter(a => a.productgroup_id === results[0].data.json[0].id)
          .sort((a, b) => (a.sort_id > b.sort_id ? 1 : -1)),
        products: results[1].data.json,
        loading: false,
      });
    });
  }

  handleSetFilterClick(filter) {
    const { products, groups } = this.state;
    this.setState({
      filter,
      sortedProducts: products
        .filter(
          item =>
            item.productgroup_id === groups.find(a => a.name === filter).id,
        )
        .sort((a, b) => (a.sort_id > b.sort_id ? 1 : -1)),
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ sortedProducts }) => ({
      sortedProducts: arrayMove(sortedProducts, oldIndex, newIndex),
    }));
  };

  saveOrder() {
    this.setState({ loading: true }, () => {
      const { sortedProducts } = this.state;
      const sortedIndexes = sortedProducts.map(product => {
        return {
          id: product.id,
          sort_id: sortedProducts.findIndex(i => product.id === i.id) + 1,
        };
      });
      const promises = [];
      sortedIndexes.forEach(product => {
        promises.push(putProduct(product.id, product));
      });
      Promise.all(promises).then(() => {
        toast.success('Produkte wurden erfolgreich sortiert.');
        this.setState({ loading: false }, () => {
          this.props.history.push(`/product`);
        });
      });
    });
  }

  render() {
    const {
      groups,
      loading,
      dropdownOpen,
      filter,
      sortedProducts,
    } = this.state;

    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex header-btn-grp">
              <div className="flex">
                <ButtonDropdown
                  style={{ alignSelf: 'center' }}
                  isOpen={dropdownOpen}
                  toggle={() => {
                    this.setState({ dropdownOpen: !dropdownOpen });
                  }}
                >
                  <DropdownToggle caret>{filter}</DropdownToggle>
                  <DropdownMenu>
                    {groups.map(item => (
                      <DropdownItem
                        onClick={() => this.handleSetFilterClick(item.name)}
                        key={item.id}
                      >
                        {item.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
                <h1 style={{ marginLeft: '0.5em' }}>Sortieren</h1>
              </div>
              <ButtonGroup>
                <Button
                  className="actions-btn-grp"
                  onClick={() => this.saveOrder()}
                >
                  Reihenfolge Speichern
                </Button>
                <Link className="actions-btn-grp" to="/product">
                  <Button outline>Abbrechen</Button>
                </Link>
              </ButtonGroup>
            </div>
            <SortableContainer
              items={sortedProducts}
              onSortEnd={this.onSortEnd}
              axis="xy"
              lockToContainerEdges={true}
            />
          </>
        )}
      </>
    );
  }
}

export default reorderProducts;
