import React, { Component } from 'react';
import { Table, Button } from 'reactstrap';
import { FaTrash, FaSearch } from 'react-icons/fa';
import { NavLink, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getUser, deleteUser } from '../../helper/api';
import Loading from '../../helper/loading';
import { getAuthName } from '../../auth/Auth';

export class User extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, users: [] };
  }

  withWindowConfirm = (func, text) => {
    const isYes = window.confirm(text);
    if (isYes) {
      func();
    }
  };

  fetchUser() {
    const login = getAuthName();
    getUser(login).then(results => {
      this.setState({ users: results.data.json, loading: false });
    });
  }

  handleTrashbinClick(user) {
    this.withWindowConfirm(() => {
      this.setState({ loading: true }, () => {
        deleteUser(user.id)
          .then(() => {
            toast.success('Kellner wurde erfolgreich gelöscht!');
            this.fetchUser();
          })
          .catch(() => {
            toast.warn('Fehler');
          });
      });
    }, `Wollen Sie ${user.username} wirklich löschen?`);
  }

  componentDidMount() {
    this.fetchUser();
  }

  render() {
    const { loading, users } = this.state;
    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div className="flex header-btn-grp">
              <h1>{users.length} Kellner</h1>
              <Link to="/user/0">
                <Button className="actions-btn-grp">Neuer Kellner</Button>
              </Link>
            </div>
            <Table className="table-withbutton" striped>
              <thead>
                <tr>
                  <th>Detail</th>
                  <th>Name</th>
                  <th>Löschen</th>
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <tr key={user.id}>
                    <td>
                      <NavLink to={`/user/${user.id}`}>
                        <FaSearch />
                      </NavLink>
                    </td>
                    <td>{user.username}</td>
                    <td>
                      <Button onClick={() => this.handleTrashbinClick(user)}>
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </>
    );
  }
}

export default User;
