import axios from 'axios';

const KEY = 'AUTH';

const API = axios.create({
  baseURL: `${process.env.REACT_APP_HTTP}://${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}`,
});

API.interceptors.request.use((config) => {
  const user = JSON.parse(sessionStorage.getItem(KEY));
  const token = user && user.token;

  if ( token != null ) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function(err) {
  return Promise.reject(err);
});

//PRODUCTS

export function getProductsWithVariations(login) {
  return API.get(`/api/product/${login}`);
}

export function getProductWithVariations(productid) {
  return API.get(`/api/productwithvariation/${productid}`);
}

export function getProducts(login) {
  return API.get(`/crud/baseproduct?login=${login}`);
}

export function deleteProductWithVariations(productid) {
  return API.delete(`/api/productwithvariation/${productid}`);
}

export function putProduct(id, product) {
  return API.put(`/crud/baseproduct/${id}`, product);
}

export function postProductWithVariations(product) {
  return API.post('/api/product', product);
}

// CATEGORIES

export function getProductGroups(login) {
  return API.get('/crud/productgroup?login=' + login);
}

export function getProductgroupById(id) {
  return API.get(`/crud/productgroup/${id}`);
}

export function putProductgroup(id, data) {
  return API.put(`/crud/productgroup/${id}`, data);
}

export function deleteProductGroup(id) {
  return API.delete(`crud/productgroup/${id}`);
}

export function postProductgroup(data) {
  return API.post(`/crud/productgroup`, data);
}

export function putProductoption(id, data) {
  return API.put(`/crud/groupoption/${id}`, data);
}

export function postProductoption(data) {
  return API.post(`/crud/groupoption`, data);
}

export function getProductoptions(login) {
  return API.get(`/crud/groupoption?porder[sort]=ASC&login=` + login);
}

export function deletetProductoption(id) {
  return API.delete(`/crud/groupoption/${id}`);
}

// ROOM

export function getRooms(login) {
  return API.get('/crud/room?login=' + login);
}

export function getRoomWithTables(roomid, login) {
  return API.get(`/api/tables/${roomid}&${login}`);
}

export function postNewRoom(data) {
  return API.post('/api/room/withtables', data);
}

export function deleteTable(id) {
  return API.delete(`/crud/table/${id}`);
}

export function deleteRoomWithTables(roomid) {
  return API.delete(`/api/room/withtables/${roomid}`);
}

// Printer

export function getPrinter() {
  return API.get('/crud/printer');
}

export function getPrinterById(id) {
  return API.get(`/crud/printer/${id}`);
}

// USER

export function getUserById(id) {
  return API.get(`/crud/user/${id}`);
}

export function getUser(login) {
  return API.get('/crud/user?login=' + login);
}

export function deleteUser(id) {
  return API.delete(`/crud/user/${id}`);
}

export function putUser(id, data) {
  return API.put(`/crud/user/${id}`, data);
}

export function postUser(data) {
  return API.post(`/crud/user`, data);
}

// procedures

export function callDelAllTablesAndRooms(login) {
  return API.post(`/api/procedures/delallrooms`, { login });
}

export function callDelAllProductsAndVariations(login) {
  return API.post(`/api/procedures/delallproducts`, { login });
}

export function callCreateTables(data) {
  return API.post(`/api/procedures/createtables`, data);
}

// data

export function GetDownloadDataString(login) {
  return `${API.defaults.baseURL}/api/data/download/${login}`;
}

export function GetUploadDataString(login) {
  return `${API.defaults.baseURL}/api/data/upload/${login}`;
}

// Auth

export function PostLoginCredentials(data) {
  return API.post(`/api/auth/check`, data);
}

export function PostNewPassword(password, login) {
  return API.post(`/api/auth/newpw`, { password, login });
}

// USERSETTING

export function putUsersetting(id, data) {
  return API.put(`/crud/usersetting/${id}`, data);
}

export function getUsersettingByLogin(login) {
  return API.get(`/crud/usersetting?login=${login}`);
}

// CHART

export function getChartPerProductByDate(login, startDate, endDate) {
  return API.get(`/api/chart/perproduct/${login}&${startDate}&${endDate}`);
}

export function getCountChartByDate(login, startDate, endDate) {
  return API.get(`/api/chart/countperproduct/${login}&${startDate}&${endDate}`);
}

// CSV

export function GetDownloadProductCsv(login, startDate, endDate) {
  return `${
    API.defaults.baseURL
  }/api/csv/perproduct/${login}&${startDate}&${endDate}`;
}
