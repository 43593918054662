import React from 'react';
import { Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import { AuthRoute } from '../auth/authRoute';

import User from './user/user';
import DetailUser from './user/detailUser';

import Room from './room/room';
import DetailRoom from './room/detailRoom';

import Product from './product/product';
import DetailProduct from './product/detailProduct';
import ReorderProducts from './product/reorderProducts';

import Productgroup from './productgroup/productgroup';
import detailProductgroup from './productgroup/detailProductgroup';

import Groupoption from './productgroup/groupoption';
import ReorderGroupoption from './productgroup/reorderGroupoption';

import Procedures from './admin/procedures/procedures';

import Backup from './admin/backup/backup';
import Password from './admin/password/password';
import Usersetting from './admin/usersetting/usersetting';

import Dashboard from './dashboard/dashboard';

function Main() {
  return (
    <Container>
      <Switch>
        <AuthRoute path="/" exact component={Dashboard} />

        <AuthRoute path="/user/" exact component={User} />
        <AuthRoute path="/user/:id" component={DetailUser} />

        <AuthRoute path="/room/" exact component={Room} />
        <AuthRoute path="/room/:id" component={DetailRoom} />

        <AuthRoute path="/productgroup/" exact component={Productgroup} />
        <AuthRoute path="/productgroup/:id" component={detailProductgroup} />

        <AuthRoute
          path="/productgroupoptions/sort/:id"
          component={ReorderGroupoption}
        />
        <AuthRoute path="/productgroupoptions/:id" component={Groupoption} />

        <AuthRoute path="/product/" exact component={Product} />
        <AuthRoute path="/product/sort" component={ReorderProducts} />
        <AuthRoute path="/product/:id" component={DetailProduct} />

        <AuthRoute path="/admin/procedures" component={Procedures} />
        <AuthRoute path="/admin/password" component={Password} />
        <AuthRoute path="/admin/usersetting" component={Usersetting} />
        <AuthRoute path="/admin/backup" component={Backup} />
      </Switch>
    </Container>
  );
}

export default Main;
