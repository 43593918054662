import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import { AuthRoute } from './components/auth/authRoute';

import Navigation from './components/navigation/navigation';
import Main from './components/body/main';

import { LoginPage } from './components/auth/LoginPage';

const Body = () => {
  return (
    <>
      <Navigation />
      <Main />
    </>
  );
};

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        transition={Slide}
        newestOnTop
      />
      <Router>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <AuthRoute path="/" component={Body} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
