import React from 'react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import Loading from '../../helper/loading';
import { getProductoptions, putProductoption } from '../../helper/api';
import { toast } from 'react-toastify';

const ItemStyle = {
  padding: '1em 1%',
  margin: '1% 10%',
  width: '80%',
  textAlign: 'center',
  boxShadow: '3px 4px 8px 0px grey',
};

const ContainerStyle = {
  margin: '2em auto 2em auto',
  width: '80%',
  display: 'flex',
  flexFlow: 'column wrap',
};

const SortableItem = sortableElement(({ value }) => (
  <div style={ItemStyle}>
    <span>{value.name}</span>
  </div>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <div style={ContainerStyle}>{children}</div>;
});

class ReorderGroupoption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      options: [],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    getProductoptions().then(res => {
      const filteredOptions = res.data.json.filter(
        o => o.productgroup_id === parseFloat(id),
      );
      this.setState({ options: filteredOptions, loading: false });
    });
  }

  saveOrder() {
    this.setState({ loading: true }, () => {
      const { options } = this.state;
      const sortedIndexes = options.map(opt => {
        return {
          id: opt.id,
          sort: options.findIndex(i => i.id === opt.id) + 1,
        };
      });

      const promises = [];
      sortedIndexes.forEach(option => {
        promises.push(putProductoption(option.id, option));
      });

      Promise.all(promises).then(() => {
        toast.success('Gruppenoptionen wurden erfolgreich sortiert!');
        const { id } = this.props.match.params;
        this.props.history.push(`/productgroupoptions/${id}`);
      });
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ options }) => ({
      options: arrayMove(options, oldIndex, newIndex),
    }));
  };

  render() {
    const { id } = this.props.match.params;
    const { options, loading } = this.state;

    if (loading) return <Loading />;

    return (
      <>
        <div className="flex header-btn-grp">
          <h1>Sortieren</h1>
          <ButtonGroup>
            <Button
              className="actions-btn-grp"
              onClick={() => this.saveOrder()}
            >
              Reihenfolge Speichern
            </Button>
            <Link className="actions-btn-grp" to={`/productgroupoptions/${id}`}>
              <Button outline>Abbrechen</Button>
            </Link>
          </ButtonGroup>
        </div>
        <SortableContainer onSortEnd={this.onSortEnd}>
          {options.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </SortableContainer>
      </>
    );
  }
}

export default ReorderGroupoption;
