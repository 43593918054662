import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Axios from 'axios';

import { GetUploadDataString } from '../../../helper/api';
import { getAuthName } from '../../../auth/Auth';
import { toast } from 'react-toastify';

const axiosHeaders = {
  'Content-Type': 'multipart/form-data',
};

const baseStyle = {
  margin: '3rem',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#2196f3',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const withWindowConfirm = (func, text) => {
  const isYes = window.confirm(text);
  if (isYes) {
    func();
  }
};

function UploadDropZone() {
  const onDrop = useCallback(acceptedFiles => {
    var formData = new FormData();
    formData.append('bonosJson', acceptedFiles[0]);

    withWindowConfirm(
      () =>
        Axios.post(GetUploadDataString(getAuthName()), formData, {
          headers: axiosHeaders,
        })
          .then(res => {
            console.log('res', res);
            toast.success(
              `${res.data.count} Rechnungen wurden erfolgreich importiert!`,
            );
          })
          .catch(err => {
            console.log('err', err);
            toast.error('Fehler beim Import!');
          }),
      'Wollen Sie die Datei wirklich hochladen?',
    );
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps({ style: baseStyle })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Hier die Datei ablegen ...</p>
      ) : (
        <p>Drag & Drop oder mit Klicken den Dateiupload starten</p>
      )}
    </div>
  );
}

export default UploadDropZone;
